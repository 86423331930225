import { addOrder } from '@/api/order'

export function addOrderToCart (order, notify) {
  addOrder(order.template, order.stack, order.protocol, order.tcpConnections, order.period, order.amount, order.operator).then(() => {
    notify.toast('Прокси сервера успешно добавлены в коризну', { color: 'success' })
    setTimeout(() => {
      document.location = process.env.VUE_APP_OFFICE_URL + '/my-cart/'
    }, 2000)
  }).catch(() => {
    notify.toast('Произошла ошибка при добавлении в корзину', { color: 'error' })
  })
}
