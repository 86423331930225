import request from './../utils/request'

export function sendFeedback (name, email, message) {
  return request({
    url: '/office/v1/feedback/',
    method: 'post',
    data: {
      name: name,
      email: email,
      message: message
    }
  })
}
