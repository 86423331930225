import request from './../utils/request'

export function fastRegistration (email, origin, utm = null, promocode = null, ref = null) {
  return request({
    url: '/office/v1/auth/fast/',
    method: 'post',
    data: {
      email: email,
      origin: origin,
      utm: utm,
      promocode: promocode,
      ref: ref
    }
  })
}

export function login (email, password, utm = null, promocode = null) {
  return request({
    url: '/office/v1/auth/login/',
    method: 'post',
    data: {
      email: email,
      password: password,
      utm: utm,
      promocode: promocode
    }
  })
}

export function info () {
  return request({
    url: '/office/v1/auth/info/',
    method: 'get'
  })
}

export function logout () {
  return request({
    url: '/office/v1/auth/logout/',
    method: 'post'
  })
}
