<template>
  <div>
    <pre v-if="!loaded" class="fingerprint">Проверка отпечатка OS</pre>
    <div v-else>
      <h5>На основе вашего последнего наблюдаемого пакета TCP/IP SYN были собраны следующие данные об отпечатке TCP/IP:</h5>
      <v-card>
        <pre class="fingerprint" v-html="colorized" />
      </v-card>
    </div>
    <div>
      <h5>Ваш User-Agent</h5>
      <div class="code">{{ userAgent }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Fingerprint',
  data () {
    return {
      loaded: false,
      data: {}
    }
  },
  computed: {
    userAgent () {
      return window.navigator.userAgent
    },
    colorized () {
      return this.prettyPrint(this.data)
    }
  },
  async mounted () {
    await this.load()
  },
  methods: {
    async load () {
      this.data = await axios.get('https://proxyzzz.com/classify?detail=1').then(r => r.data)
      this.loaded = true
    },
    replacer (match, pIndent, pKey, pVal, pEnd) {
      const key = '<span class=json-key>'
      const val = '<span class=json-value>'
      const str = '<span class=json-string>'
      let r = pIndent || ''
      if (pKey) { r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ' }
      if (pVal) { r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>' }
      return r + (pEnd || '')
    },
    prettyPrint (obj) {
      const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg
      return JSON.stringify(obj, null, 3)
        .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
        .replace(/</g, '&lt;').replace(/>/g, '&gt;')
        .replace(jsonLine, this.replacer)
    }
  }
}
</script>

<style>
.fingerprint {
  background-color: #d2d2d2;
  color: brown;
  padding: 1em;
}
.code {
  background-color: #e7e7e7;
  border-left: #d2d2d2 solid 1em;
  padding: .3em .3em .3em 0.5em;
}
.json-key {
  color: brown;
}
.json-value {
  color: navy;
}
.json-string {
  color: olive;
}
</style>
