<template>
  <div data-app id="feedback-widget">
    <v-card style="padding: 1em">
      <div v-if="!send">
        <h5>Свяжитесь с нами</h5>

        <v-form ref="form" lazy-validation>
          <v-text-field v-if="false" v-model="name" label="Введите Ваше имя" :rules="nameRules" />
          <v-text-field v-model="email" label="Введите Ваш адрес электронной почты" :rules="emailRules" />
          <v-textarea v-model="message" name="input-7-4" label="Введите сообщение" :rules="messageRules" />
        </v-form>

        <div v-if="error" class="mb-3" style="color: red">{{ error }}</div>

        <v-btn dark depressed @click="sendMessage">Отправить</v-btn>
      </div>
      <div v-else style="padding: 2em">
        Ваше обращение успешно отправлено
      </div>
    </v-card>
  </div>
</template>

<script>
import { sendFeedback } from '@/api/feedback'

const key = 'proxyzzz:feedback'

export default {
  name: 'FeedbackWidget',
  data () {
    return {
      name: '',
      email: '',
      message: '',
      nameRules: [
        value => !!value || 'Это поле обязательно'
      ],
      emailRules: [
        value => !!value || 'Это поле обязательно',
        value => (value && value.length >= 5 && value.indexOf('@') !== -1) || 'Введите правильный адрес электронной почты'
      ],
      messageRules: [
        value => !!value || 'Это поле обязательно'
      ],
      send: false,
      error: ''
    }
  },
  mounted () {
    const lastMessageTime = parseInt(localStorage.getItem(key) || '0')
    const timeLeft = lastMessageTime + 60000 - new Date().getTime()
    if (timeLeft > 0) {
      this.send = true
      setTimeout(() => {
        this.send = false
      }, timeLeft)
    }
  },
  methods: {
    sendMessage () {
      if (this.$refs.form.validate()) {
        sendFeedback(this.name, this.email, this.message).then(response => {
          this.send = true
          localStorage.setItem(key, (new Date().getTime()) + '')
        }).catch(e => {
          if (e === 'throttled') {
            this.error = 'Слишком много попыток'
          } else {
            this.error = 'Возникла ошибка при отправке сообщения'
          }
        })
      } else {
        this.error = 'Заполните все поля формы'
      }
    }
  }
}
</script>

<style>
#feedback-widget {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
</style>
