<template>
  <div data-app id="login-widget">
    <v-dialog v-if="visible" v-model="visible" persistent max-width="400px" attach="#login-widget" content-class="bg-white">
      <v-card-title>
        <span class="text-h5 mx-auto">Авторизация</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="loginForm" v-model="valid" lazy-validation>
          <v-text-field v-model="email" label="Введите адрес электронной почты" required :rules="emailRules" @keydown.enter.prevent.native="login"/>
          <v-text-field ref="password" v-if="!fastRegistrationMode" v-model="password" label="Пароль" :type="passwordVisibleMode ? '' : 'password'" required prepend-icon="mdi-form-textbox-password" :append-icon="passwordVisibleMode ? 'mdi-eye-off' : 'mdi-eye'" @click:append="passwordVisibleMode = !passwordVisibleMode" :rules="passwordRules" @keydown.enter.prevent.native="login"/>
        </v-form>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="visible = false">Отмена</v-btn>
        <v-btn color="blue darken-1" text @click="login">Продолжить</v-btn>
      </v-card-actions>
    </v-dialog>
  </div>
</template>

<script>

import { fastRegistration, login, logout } from '@/api/user'
import { removeToken, setToken } from '@/utils/token'
import { addOrderToCart } from '@/utils/cart'

const utmStorageKey = 'proxyzzz:utm'
const promocodeStorageKey = 'proxyzzz:promocode'
const refStorageKey = 'proxyzzz:ref'

export default {
  name: 'LoginWidget',
  components: {},
  data () {
    return {
      visible: false,
      email: '',
      password: '',
      fastRegistrationMode: true,
      passwordVisibleMode: false,
      emailRules: [
        v => !!v || 'Введите email адрес',
        v => /.+@.+\..+/.test(v) || 'Неверно указан email адрес'
      ],
      passwordRules: [
        v => !!v || 'Введите пароль',
        v => (v && v.length >= 8) || 'Минимальная длина пароля 8 символов'
      ],
      valid: true,
      error: null,
      order: null
    }
  },
  mounted () {
    this.updateFirstReferrer()
    this.parseUtm()

    document.addEventListener('app:login', e => {
      this.order = e.detail
      this.visible = true
    })
    const loginLink = document.getElementById('login-link')
    if (loginLink) {
      loginLink.onclick = (e) => {
        e.preventDefault()
        this.order = null
        this.visible = true

        this.email = localStorage.getItem('proxyzzz:email') || ''
        if (this.email.trim().length > 0) {
          this.fastRegistrationMode = false

          this.$nextTick(() => {
            this.$refs.password.focus()
          })
        }
      }
    }
    const logoutLink = document.getElementById('logout-link')
    if (logoutLink) {
      logoutLink.onclick = (e) => {
        e.preventDefault()
        logout().then(response => {
          localStorage.removeItem('proxyzzz:email')
          removeToken()
          this.$cookie.delete(response.data.session)
          document.location.reload()
        }).catch(() => {
          document.location.reload()
        })
      }
    }
    const managerLink = document.getElementById('manager-link')
    if (managerLink) {
      managerLink.href = process.env.VUE_APP_MANAGER_URL + '/#/'
      // managerLink.onclick = (e) => {
      //   e.preventDefault()
      //   document.location = process.env.VUE_APP_MANAGER_URL + '/#/clients'
      // }
    }
  },
  methods: {
    login () {
      this.error = null
      if (this.$refs.loginForm.validate()) {
        const utm = localStorage.getItem(utmStorageKey)
        const promocode = localStorage.getItem(promocodeStorageKey)
        const ref = localStorage.getItem(refStorageKey)

        if (this.fastRegistrationMode) {
          fastRegistration(this.email, document.location.origin, utm, promocode, ref).then(response => {
            window.sendYandexMetrics('Registration')
            this.onLoginSuccess(response.data)
          }).catch(e => {
            if (e === 'email_already_exists') {
              this.fastRegistrationMode = false
              this.$nextTick(() => {
                this.$refs.password.focus()
              })
            } else if (e === 'registration_limit') {
              this.error = 'Что-то пошло не так. Обратитесь в поддержку для завершения регистрации'
            } else {
              this.error = 'Произошла непредвиденная ошибка. Повторите запрос позже'
            }
          })
        } else {
          login(this.email, this.password, utm, promocode).then(response => {
            window.sendYandexMetrics('Login')
            this.onLoginSuccess(response.data)
          }).catch(e => {
            if (e === 'invalid_email_or_password') {
              this.error = 'Неверный адрес электронной почты или пароль'
              this.password = ''
            } else if (e === 'throttled') {
              this.error = 'Слишком много попыток. Повторите запрос позже'
            }
          })
        }
      }
    },
    updateFirstReferrer () {
      if (document.referrer) {
        if (!localStorage.getItem('proxyzzz:first-referrer')) {
          localStorage.setItem('proxyzzz:first-referrer', document.referrer)
        }
      }
    },
    onLoginSuccess (data) {
      this.visible = false
      setToken(data.token)
      localStorage.removeItem(promocodeStorageKey)
      localStorage.removeItem(refStorageKey)
      localStorage.setItem('proxyzzz:email', this.email)
      this.$cookie.set(data.session, data['session-key'], 365)
      window.carrotquest.identify({ op: 'update_or_create', key: '$email', value: this.email })
      this.$nextTick(() => {
        if (this.order) {
          addOrderToCart(this.order, this.$notify)
        } else if (this.order === null) {
          document.location = process.env.VUE_APP_OFFICE_URL + '/my-cart/?component=configurator'
        } else {
          document.location.reload()
        }
      })
    },
    parseUtm () {
      const query = this.parseQuery()
      if (localStorage.getItem(utmStorageKey) === null) {
        const data = Object.keys(query).filter(key => key.indexOf('utm_') !== 1).reduce((obj, key) => {
          return {
            ...obj,
            [key]: query[key]
          }
        }, {})

        if (Object.keys(data).length > 0) {
          localStorage.setItem(utmStorageKey, JSON.stringify(data))
        }
      }

      if (Object.prototype.hasOwnProperty.call(query, 'promocode')) {
        localStorage.setItem(promocodeStorageKey, query.promocode)
      }

      if (Object.prototype.hasOwnProperty.call(query, 'ref')) {
        localStorage.setItem(refStorageKey, query.ref)
      }
    },
    parseQuery () {
      const uri = window.location.href.split('?')
      const getVars = {}
      if (uri.length === 2) {
        const vars = uri[1].split('&')
        let tmp = ''
        vars.forEach(v => {
          tmp = v.split('=')
          if (tmp.length === 2) {
            getVars[tmp[0]] = tmp[1]
          }
        })
      }
      return getVars
    }
  }
}
</script>

<style>
.bg-white {
  background-color: white;
}
.v-snack:not(.v-snack--absolute) {
  z-index: 2000 !important;
}
</style>
