import axios from 'axios'
import { getToken } from './token'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = getToken()
    if (token) {
      config.headers.Authorization = 'Token ' + token
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (response.data.error !== null) {
      if (!response.data.data) {
        return Promise.reject(response.data.error)
      }

      const r = {
        message: response.data.error,
        data: response.data.data
      }

      return Promise.reject(r)
    }

    return res
  },
  error => {
    if (error.response.status === 401) {
      return Promise.reject(error)
    }

    if (error.response.data.error) {
      console.log(error.response.data.error)
      return Promise.reject(error)
    }

    console.log(error.message)
    return Promise.reject(error)
  }
)

export default service
