import request from '@/utils/request'

export function addOrder (template, stack, protocol, tcpConnections, period, amount, operator) {
  return request({
    url: '/office/v1/order-configurator/add-to-cart/',
    method: 'post',
    data: {
      template: template,
      stack: stack,
      protocol: protocol,
      tcp_connections: tcpConnections,
      period: period,
      amount: amount,
      operator: operator
    }
  })
}
