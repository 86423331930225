<template>
  <div data-app id="proxy-check-widget">
    <div class="proxy-checker-container">
      <v-card class="proxy-checker-description" max-width="460">
        В каком формате добавлять прокси в прокси-чекер?<br><br>
        Если у вас <b>публичные прокси</b> (без логина и пароля), то <b>IP:PORT</b><br>
        Если у вас <b>приватные прокси</b> (с авторизацией по логину и паролю), то <b>IP:PORT:USER:PASS</b><br><br>
        P.S.: Если вы купили прокси у нас, то они приватные!
      </v-card>
      <div class="proxy-checker-form">
        <v-textarea v-model="task" solo name="input-7-4" label="Список прокси" :readonly="processing" />
        <v-btn :disabled="processing" @click="send('a1a441c5-157f-4b45-8099-7b3d8d3b63dc')">Проверить</v-btn>
      </div>
    </div>
    <div>
      <div v-if="error" style="color: red; text-align: center">{{ error }}</div>
      <h2 v-if="results.length > 0">Результат</h2>
      <v-progress-linear v-if="processing" indeterminate color="green" height="15"></v-progress-linear>
      <v-simple-table v-if="results.length > 0">
        <thead>
        <tr>
          <th class="text-left">Статус</th>
          <th class="text-left">IP:Порт</th>
          <th class="text-left">Логин</th>
          <th class="text-left">Пароль</th>
          <th class="text-left">Тип</th>
          <th class="text-left">Время ответа</th>
          <th class="text-left">IP</th>
          <th class="text-left">Страна</th>
          <th class="text-left">OS/Стек</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in results" :key="item.address + ':' + item.port">
          <td>
            <v-icon :color="item.success ? 'green' : 'red'" :style="{ color: item.success ? 'green' : 'red' }">
              {{ item.success ? 'mdi-check' : 'mdi-alert-circle-outline' }}
            </v-icon>
          </td>
          <td>{{ item.address }}:{{ item.port }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.password }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.duration }}</td>
          <td>{{ item.ip }}</td>
          <td>{{ item.country }}</td>
          <td>{{ item.stack }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProxyChecker',
  data () {
    return {
      task: '',
      results: [],
      processing: false,
      error: ''
    }
  },
  methods: {
    executeRecaptcha () {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Lf-wpIiAAAAABTkPHu4xGjbd_RfQdm1wQgtJlIQ', { action: 'proxychecker' }).then(this.send)
      })
    },
    send (token) {
      this.error = ''
      this.results = []
      this.processing = true

      const ws = new WebSocket(process.env.VUE_APP_PROXY_CHECKER_WS)

      ws.onopen = () => {
        ws.send(token)
        ws.send('proxy-check')
        ws.send(this.task)
      }

      ws.onmessage = (event) => {
        const item = JSON.parse(event.data)
        if (item.valid) {
          this.results.push(item)
        }
      }

      ws.onclose = () => {
        this.processing = false
      }

      ws.onerror = () => {
        this.processing = false
        // this.error = 'Произошла непредвиденная ошибка. Повторите запрос позже.'
      }
    }
  }
}
</script>

<style>
#proxy-check-widget {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.proxy-checker-container {
  display: flex;
  margin: 0 auto;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
}

.proxy-checker-container > div {
  margin-right: 1em;
  margin-bottom: 1em;
}

.proxy-checker-form {
  width: 460px;
}

.proxy-checker-description {
  padding: 1em;
  text-align: left;
}

.v-progress-linear__indeterminate.long {
  background-color: black !important;
}

.v-progress-linear__indeterminate.short {
  background-color: #3cc !important;
}

</style>
