<template>
  <div>
    <v-form onSubmit="return false;">
      <v-text-field
        v-model="value"
        :loading="loading"
        label="Введите данные прокси"
        hint="address:port:username:password"
        append-icon="mdi-magnify"
        solo
        single-line
        persistent-hint
        :rules="[rules.required, rules.proxySchema]"
        @click:append="submit"
        @keyup.enter.prevent.stop="submit"
      />
    </v-form>
    <v-card>
      <pre v-if="data" class="fingerprint" v-html="colorized" />
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Fingerprint',
  data () {
    return {
      loading: false,
      value: '',
      data: null,
      rules: {
        required: value => !!value || 'Это поле обязательно',
        proxySchema: value => {
          const parts = value.split(':')
          return (parts.length === 4 || parts.length === 2) || 'Введите правильный формат прокси сервера'
        }
      }
    }
  },
  computed: {
    colorized () {
      return this.prettyPrint(this.data)
    }
  },
  mounted () {
    // this.value = this.$route.query.proxy || ''
    // if (this.value !== '') {
    //   this.submit()
    // }
  },
  methods: {
    submit () {
      this.loading = true
      this.data = null
      const ws = new WebSocket(process.env.VUE_APP_PROXY_CHECKER_WS)

      ws.onopen = () => {
        ws.send('a1a441c5-157f-4b45-8099-7b3d8d3b63dc')
        ws.send('proxy-fingerprint')
        ws.send(this.value)
      }

      ws.onmessage = (event) => {
        this.data = JSON.parse(event.data)
      }

      ws.onclose = () => {
        this.loading = false
      }

      ws.onerror = () => {
        this.processing = false
        // this.error = 'Произошла непредвиденная ошибка. Повторите запрос позже.'
      }
    },
    replacer (match, pIndent, pKey, pVal, pEnd) {
      const key = '<span class=json-key>'
      const val = '<span class=json-value>'
      const str = '<span class=json-string>'
      let r = pIndent || ''
      if (pKey) { r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ' }
      if (pVal) { r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>' }
      return r + (pEnd || '')
    },
    prettyPrint (obj) {
      const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg
      return JSON.stringify(obj, null, 3)
        .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
        .replace(/</g, '&lt;').replace(/>/g, '&gt;')
        .replace(jsonLine, this.replacer)
    }
  }
}
</script>

<style>
.fingerprint {
  background-color: #d2d2d2;
  color: brown;
  padding: 1em;
}
.code {
  background-color: #e7e7e7;
  border-left: #d2d2d2 solid 1em;
  padding: .3em .3em .3em 0.5em;
}
.json-key {
  color: brown;
}
.json-value {
  color: navy;
}
.json-string {
  color: olive;
}
</style>
