<template>
  <span></span>
</template>

<script>
export default {
  name: 'Site',
  mounted () {
    const calculatorBanner = document.getElementsByClassName('calculator-banner')
    if (calculatorBanner.length > 0) {
      document.body.setAttribute('style', 'padding-bottom: 100px')
      calculatorBanner[0].removeAttribute('style')

      const closeButton = document.getElementsByClassName('calculator-banner-close-btn')
      if (closeButton.length === 1) {
        closeButton[0].addEventListener('click', () => {
          calculatorBanner[0].setAttribute('style', 'display: none')
          document.body.removeAttribute('style')
        })
      }
    }
  }
}
</script>
