import request from './../utils/request'

export const ConfiguratorApi = {
  baseUrl: '/configurator/v1',

  availableProxies (kind) {
    return request({
      url: `${this.baseUrl}/available-proxies/`,
      method: 'get',
      params: {
        kind: kind
      }
    })
  },

  countries () {
    return request({
      url: `${this.baseUrl}/countries/`,
      method: 'get'
    })
  },

  locations (country) {
    return request({
      url: `${this.baseUrl}/locations/`,
      method: 'get',
      params: {
        country: country
      }
    })
  },

  types (location, operator) {
    return request({
      url: `${this.baseUrl}/types/`,
      method: 'get',
      params: {
        location: location,
        operator: operator
      }
    })
  },

  subTypes () {
    return request({
      url: `${this.baseUrl}/sub-types/`,
      method: 'get'
    })
  },

  stacks (location, operator) {
    return request({
      url: `${this.baseUrl}/stacks/`,
      method: 'get',
      params: {
        location: location,
        operator: operator
      }
    })
  },

  periodLimits (type) {
    return request({
      url: `${this.baseUrl}/period-limits/`,
      method: 'get',
      params: {
        type: type
      }
    })
  },

  price (type, period, amount, priceType, tariff) {
    return request({
      url: `${this.baseUrl}/price/`,
      method: 'get',
      params: {
        type: type,
        period: period,
        amount: amount,
        price_type: priceType,
        tariff: tariff
      }
    })
  },

  tariffs () {
    return request({
      url: `${this.baseUrl}/tariffs/`,
      method: 'get'
    })
  }
}
